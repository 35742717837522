import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '../block-content'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

const SecondSection = ({ banner, bio }) => (
  <div className='about-second-section grid'>
    <div className='banner'>
      <span>{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;{banner}&nbsp;·&nbsp;</span>
    </div>
    <div className='bio' data-sal='fade' data-sal-delay='500' data-sal-duration='1000' data-sal-easing='ease'>
      <BlockContent blocks={bio || []}/>
    </div>
    <Link data-sal='slide-down' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out' className='cta' to='/projects'>Explore our work <Arrow/></Link>
  </div>
)

export default SecondSection
