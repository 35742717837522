import React from 'react'
import Image from '../image'
import BlockContent from '../block-content'

const FirstSection = ({ title, subtitle, image, bio }) => (
  <div className='about-first-section grid'>
    <div className='about-image'>
      <Image fluid={image} alt='Alejandro de León - Arquetipo Estudio'/>
    </div>
    <div className='titles'>
      <h1 data-sal='slide-down' data-sal-delay='100' data-sal-duration='700' data-sal-easing='ease-out'>{title}</h1>
      <h2 data-sal='fade' data-sal-delay='200' data-sal-duration='700' data-sal-easing='ease-out'>{subtitle}</h2>
    </div>
    <div className='bio' data-sal='fade' data-sal-delay='500' data-sal-duration='1000' data-sal-easing='ease'>
      <BlockContent blocks={bio || []}/>
    </div>
  </div>
)

export default FirstSection
