import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FirstSection from '../components/about/first-section'
import SecondSection from '../components/about/second-section'

export const query = graphql`
  query AboutQuery {
    about: sanityAbout {
      title
      subtitle
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawBioPartOne
      _rawBioPartTwo
      banner
    }
  }
`

const AboutPage = ({ data }) => {
  const { title, subtitle, image, _rawBioPartOne, _rawBioPartTwo, banner } = data.about

  useEffect(() => {
    const logo = document.querySelector('.logo')
    logo.classList.add('white')
  })

  return(
    <Layout>
      <SEO title='About'/>
      <FirstSection image={image.asset.fluid} title={title} subtitle={subtitle} bio={_rawBioPartOne}/>
      <SecondSection banner={banner} bio={_rawBioPartTwo}/>
    </Layout>
  )
}

export default AboutPage
